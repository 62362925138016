import { BottomNavigationActionProps, Box, TabProps, TabsProps } from '@mui/material'
import { SyntheticEvent, useEffect, useState } from 'react'
import LinkTab from '~/src/components/mui-wrappers/styled/link-tab'
import Tabs from '~/src/components/mui-wrappers/styled/tabs'
import Title from '~/src/components/mui-wrappers/Title'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useTranslations } from '~/src/hooks/useTranslations'
import CustomNavItemProps from '~/src/types/CustomNavItemProps'
import { useLocation } from 'react-router-dom'

export interface TabsHeaderTabsProps extends TabsProps {
  navItems: CustomNavItemProps[] | BottomNavigationActionProps[],
  onTabChange(path: string): string | null,
}

interface Props {
  title: string,
  TabsProps: TabsHeaderTabsProps,
}

const TabsHeader = ({ title, TabsProps: { navItems, onTabChange, ...TabsProps } }: Props) => {
  const { pathname } = useLocation()
  const theme = useCustomMuiTheme()
  const [ selected, setSelected ] = useState<string | boolean>(false)
  const { t } = useTranslations()

  const handleChange = (event: SyntheticEvent, value: string) => {
    setSelected(value)
  }

  const handleOnClick = () => {
    setSelected(onTabChange(pathname) || false)
  }

  useEffect(() => {
    setSelected(onTabChange(pathname) || false)
  }, [ pathname ])

  return (
    <Box
      component='header'
      sx={{
        gridArea: 'header',
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        overflow: 'hidden'
      }}>
      <Title
        variant='h3'
        sx={{
          color: 'background.contrastText',
          [theme.breakpoints.down('sm')]: {
            display: 'none'
          }
        }}
      >
        {title ?? t('profile.accountDetails')}
      </Title>
      <Tabs
        value={selected}
        onChange={handleChange}
        variant='scrollable'
        scrollButtons='auto'
        {...TabsProps}
      >
        {navItems.map((navItemProps: CustomNavItemProps | BottomNavigationActionProps, index: number) => (
          <LinkTab
            key={index}
            onClick={handleOnClick}
            resolve
            {...navItemProps as TabProps}
          />
        ))}
      </Tabs>
    </Box>
  )
}

export default TabsHeader
